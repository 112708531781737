import { useState, useContext, createContext } from "react";

const MealContext = createContext();

export const useMeal = () => useContext(MealContext);

export const MealProvider = ({ children }) => {
  const [breakfast, setBreakfast] = useState([]);
  const [lunch, setLunch] = useState([]);
  const [dinner, setDinner] = useState([]);
  const [eatingOutMenuList, setEatingOutMenuList] = useState([]);

  return (
    <MealContext.Provider
      value={{
        breakfast,
        lunch,
        dinner,
        setBreakfast,
        setLunch,
        setDinner,
        eatingOutMenuList,
        setEatingOutMenuList
      }}
    >
      {children}
    </MealContext.Provider>
  );
};

export default MealContext;
