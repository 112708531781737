import React, { useContext, useEffect, useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "./contexts/authContext";
import { BrowserRouter, useLocation } from "react-router-dom";

// auth provider

import routes, { renderRoutes } from "./routes";
import MealContext from "./contexts/mealContext";
import eventEmitter from "./event/event";

const App = () => {
  const { auth, setAuth, setUserID, setUsername } = useContext(AuthContext);
  const { breakfast, setBreakfast, lunch, setLunch, dinner, setDinner } =
    useContext(MealContext);
  const { username, userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    currentDate.toISOString().split("T")[0]
  );
  eventEmitter.emit("filterByDate", selectedDate);
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    //console.log("Retrieved token:", token);
    if (token) {

      verifyToken(token);
    } else {

      navigate("/");
    }

  }, []);

  function verifyToken(token) {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/verifyToken`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return refreshAccessToken();
        }
      })
      .then((data) => {
        if (data.is_valid) {
          setAuth(data.is_valid);
          setUsername(data.username);
          setUserID(data.userID);
        } else {
          localStorage.removeItem(" ");
          setAuth(false);
          setUserID(null);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error verifying or refreshing token:", error);
        setAuth(false);
        setUserID(null);
      });
  }
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      const fetchTokens = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/auth/callback?code= + ${code}`
          );
          if (response.ok) {
          } else {
            console.error("Error retrieving tokens");
          }
        } catch (error) {
          console.error("Error fetching tokens:", error);
        }
      };

      fetchTokens();
    }
  }, [location]);

  function refreshAccessToken() {
    fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh`, {
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to refresh token");
        }
        return response.json();
      })
      .then((data) => {

        localStorage.setItem("access_token", data.data.accessToken);
        return {
          is_valid: true,
          user: get_jwt_identity(data.data.accessToken),
        };
      })
      .catch((error) => {
        console.error("Refresh token failed", error);
        return { is_valid: false };
      });
  }

  function get_jwt_identity(token) {
    if (!token) {
      console.error("Token is undefined or null.");
      return null;
    }
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload).identity;
    } catch (e) {
      console.error("Failed to parse token:", e);
      return null;
    }
  }
  const post_options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "Content-Type": "application/json",
    },
  };

  useEffect(() => {
    const fetchMealsAndEvents = async () => {
      try {
        const fetchBreakfast = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchBreakfast`,
          post_options
        );
        const breakfastData = await fetchBreakfast.json();
        let BreakfastMeals = breakfastData.data.result;
        const updatedBreakfast = BreakfastMeals.map((Breakfast) => ({
          mealType: "Breakfast",
          mealId: Breakfast?.mealId,
          mealName: Breakfast?.mealName,
          mealRecipe: Breakfast?.mealRecipe,
          mealIngredients: Breakfast?.mealIngredients,
          mealCalories: Breakfast?.mealCalories,
          mealProtein: Breakfast?.mealProtein,
          mealCarbs: Breakfast?.mealCarbs,
          mealfat: Breakfast?.mealFat,
          cookingTime: Breakfast?.mealCookingTimes,
          dateEaten: Breakfast?.dateEaten,
          isLiked: Breakfast?.isLiked
        }));

        setBreakfast(updatedBreakfast);

        const fetchLunch = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchLunch`,
          post_options
        );
        const lunchData = await fetchLunch.json();
        let LunchMeals = lunchData.data.result;
        const updatedLunch = LunchMeals.map((Lunch) => ({
          mealType: "Lunch",
          mealId: Lunch?.mealId,
          mealName: Lunch?.mealName,
          mealRecipe: Lunch?.mealRecipe,
          mealIngredients: Lunch?.mealIngredients,
          mealCalories: Lunch?.mealCalories,
          mealProtein: Lunch?.mealProtein,
          mealCarbs: Lunch?.mealCarbs,
          mealfat: Lunch?.mealFat,
          cookingTime: Lunch?.mealCookingTimes,
          dateEaten: Lunch?.dateEaten,
          isLiked: Lunch?.isLiked
        }));
        setLunch(updatedLunch);

        const fetchDinner = await fetch(
          `${process.env.REACT_APP_API_URL}/api/meals/fetchDinner`,
          post_options
        );
        const dinnerData = await fetchDinner.json();
        let DinnerMeals = dinnerData.data.result;
        const updatedDinner = DinnerMeals.map((Dinner) => ({
          mealType: "Dinner",
          mealId: Dinner?.mealId,
          mealName: Dinner?.mealName,
          mealRecipe: Dinner?.mealRecipe,
          mealIngredients: Dinner?.mealIngredients,
          mealCalories: Dinner?.mealCalories,
          mealProtein: Dinner?.mealProtein,
          mealCarbs: Dinner?.mealCarbs,
          mealfat: Dinner?.mealFat,
          cookingTime: Dinner?.mealCookingTimes,
          dateEaten: Dinner?.dateEaten,
          isLiked: Dinner?.isLiked

        }));
        setDinner(updatedDinner);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchMealsAndEvents();
  }, [username]);

  return (
    <React.Fragment>
      {renderRoutes(routes)}
    </React.Fragment>
  );
};

export default App;
